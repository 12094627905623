<template>
    <div class="card center card-edge">
        <h5>GlobalProtect VPN Install Page</h5>
    </div>

    <div class="card center card-edge">
        <div class="content paragraph">
            You must run the GlobalProtect VPN on your device if you are not in the office, otherwise the device won’t be able to find non-prod environments (NAT). If you run the app without having GlobalProtect connected to the VPN, you will get a warning that the app won’t work.
        </div>
        <div class="space"/>
        <div class="content paragraph">
            The Portal should be set to myvpn.oclc.org
        </div>

        <div class="space"/>
        <div class="card-header">
            iOS install GlobalProtect VPN
        </div>

        <div class="space"/>
        <a class="download" href="https://apps.apple.com/us/app/globalprotect/id1400555706">Install GlobalProtect VPN from App Store</a>
        <div class="space"/>

        <div class="card-header">
            Android install GlobalProtect VPN
        </div>
        <div class="space"/>
        <a class="download" href="https://play.google.com/store/apps/details?id=com.paloaltonetworks.globalprotect&hl=en_US&gl=US">Install GlobalProtect VPN from Google Play</a>
        <div class="space"/>

    </div>
</template>

<style scoped>
    .paragraph {
        color: #777;
    }
</style>
