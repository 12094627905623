export default function useSession() {

    const token = "oclc"
    const framework = "Vue"
    const name = "session"
    const bootstrap = "29tMyE"
    const storage = "VzNsY"
    const sessionName = "mobileinstallersession"

    return {
        sessionName,
        token,
        framework,
        bootstrap,
        name,
        storage
    }
}
