export const userService = {
    login,
    logout,
    isLoggedIn
}

function login(token1, token2, session) {

    let result = false

    if (token1 === session.token) {
        if (compare(window.btoa(token2), session)) {
            localStorage.setItem(session.sessionName, session.storage + session.bootstrap + "=")
            result = true
        }
    }

    return result
}

function logout(session) {
  // remove user from local storage to log user out
  localStorage.removeItem(session.sessionName)
}

function isLoggedIn(session) {
    const value = localStorage.getItem(session.sessionName)
    return compare(value, session)
}

function compare(value, session) {
    return value === session.storage + session.bootstrap + "="
}
