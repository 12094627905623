<template>
    <app-install-details title="Worldcat App Install Page" app="wcmobile" filename="WorldCat" repository="http://git.ent.oclc.org/projects/MOBILE/repos/wcmobile"/>
</template>

<script>
import AppInstallDetails from "@/components/AppInstallDetails.vue"

export default {
    components: {
        AppInstallDetails
    }
}
</script>
