import { createWebHistory, createRouter } from "vue-router"
import Home from "@/pages/Install.vue"
import Digby from "@/pages/Digby.vue"
import Worldcat from "@/pages/WorldCat.vue"
import Certificate from "@/pages/Certificate.vue"
import Login from "@/pages/Login.vue"
import Vpn from "@/pages/Vpn.vue"
import { userService } from "@/service/user.js"
import useSession from "@/composables/useSession"

const routes = [
    {
        path: "/",
        redirect: "/install"
    },
    {
        path: "/install",
        name: "Install",
        component: Home
    },
    {
        path: "/install/digby",
        name: "Digby",
        component: Digby
    },
    {
        path: "/install/wcmobile",
        name: "Worldcat",
        component: Worldcat
    },
    {
        path: "/install/certificate",
        name: "Certificate",
        component: Certificate
    },
    {
        path: "/install/vpn",
        name: "Vpn",
        component: Vpn
    },
    {
        path: "/install/login",
        component: Login
    }
    // {
    //     path: "*",
    //     redirect: "/"
    // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/install/login"]
    const authRequired = !publicPages.includes(to.path)
    const session = useSession()

    if (authRequired && !userService.isLoggedIn(session)) {
        return next({
            path: "/install/login",
            query: { returnUrl: to.path }
        })
    }

    next()
})

export default router
