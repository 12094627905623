<template>
    <div class="card center card-edge">
        <h5>OCLC Mobile App Install Page</h5>
    </div>

    <div class="card center card-edge">

        <p class="center">
            <router-link class="btn link" to="/install/digby">Digby</router-link>
        </p>

        <p class="center">
            <router-link class="btn link" to="/install/wcmobile">WorldCat Find</router-link>
        </p>

        <p class="center">
            <router-link class="btn link" to="/install/certificate">Install OCLC Root CA certificate</router-link>
        </p>

        <p class="center">
            <router-link class="btn link" to="/install/vpn">Install GlobalProtect VPN</router-link>
        </p>

    </div>

</template>

<style scoped>
    .link {
        width: 90%;
        margin-bottom: 0.75em;
        font-size: 13px;
        padding-top: 1em;
        padding-bottom: 1em;
        line-height: 15px;
        height: 3em;
        background-color: lightsteelblue;
    }
</style>
