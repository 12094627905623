<template>
    <div class="card center card-edge">
        <h5>Login</h5>
    </div>
    <div class="card center card-edge">

        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="loading">Login</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { userService } from "@/service/user"
import useSession from "@/composables/useSession"

export default {

    setup() {
        const session = useSession()
        const route = useRoute()
        const router = useRouter()

        const username = ref("")
        const password = ref("")
        const returnUrl = ref("")
        const error = ref("")
        const submitted = ref(false)
        const loading = ref(false)

        onMounted(() => {
            // reset login status
            // userService.logout()

            // get return url from route parameters or default to '/'
            returnUrl.value = route.query.returnUrl || "/"
        })

        function handleSubmit() {
            submitted.value = true

            if (username.value && password.value) {
                loading.value = true

                if (userService.login(username.value, password.value, session)) {
                    error.value = ""
                    loading.value = false
                    router.push(returnUrl.value)
                } else {
                    error.value = "Invalid login."
                    loading.value = false
                }

            } else {
                error.value = "You must supply a username and password"
            }

        }

        return {
            username,
            password,
            submitted,
            loading,
            returnUrl,
            error,
            handleSubmit
        }
    }
}
</script>
