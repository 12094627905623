<template>

    <div class="card center card-edge">
        <h5>{{ title }}</h5>
    </div>

    <div class="card center card-edge">
        <div class="card-header">
            Test
        </div>

        <div v-for="testRelease in testReleases" :key="testRelease">
            <div class="space"/>
            <div class="download">
                <i class="material-icons dp48">download</i>
                <a class="download" :href="`itms-services://?action=download-manifest&url=${packageUrl}/static/${app}/dist/test/${testRelease}/manifest.plist`">
                        {{ app }} - iPhone {{ testRelease }}
                </a>
            </div>
            <div class="space"/>
            <div class="download">
                <i class="material-icons dp48">download</i>
                <a class="download" :href="`${packageUrl}/static/${app}/dist/test/${testRelease}/${filename}.apk`">
                        {{ app }} - Android {{ testRelease }}
                </a>
            </div>
        </div>
        <div class="space"/>
    </div>

    <div class="card center card-edge">
        <div class="card-header">
            Emulator
        </div>
        <a class="download" :href="`${packageLocalUrl}/${app}/dist/emulator`">Emulator versions (internal network only)</a>
    </div>

    <div class="card center card-edge">
        <div class="card-header">
            Release
        </div>
        <a class="download" :href="`${packageLocalUrl}/${app}/dist/release`">Release versions (internal network only)</a>
    </div>

    <div class="card center card-edge">
        <div class="card-header">
            Release Notes
        </div>
        <a class="download" :href="`${repository}/browse/RELEASE-NOTES.md`" target="_blank">Release notes can be found here (internal network only)</a>
    </div>

</template>

<script>
import { onMounted, ref } from "vue"
import config from "@/shared/config"
const sort = require("version-sort")

export default {

    props: {
        title: String,
        app: String,
        filename: String,
        repository: String
    },

    setup(props) {

        const testReleases = ref()

        onMounted(async() => {
            const response = await fetch(`${config.s3_download_url}/static/${props.app}/build-manifest.json`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const manifest = await response.json()
            const versions = manifest.testReleases.map(version => version.substring(1))
            console.log("versions", versions)
            const sortedVersions = sort(versions).map(version => `v${version}`)
            console.log("sortedVersions", sortedVersions)
            const highestToLowest = sortedVersions.filter(v => !v.startsWith("v0")).reverse()
            console.log("highestToLowest", highestToLowest)

            testReleases.value = highestToLowest.slice(0, config.num_releases ? config.num_releases : highestToLowest.length)
            console.log("testReleases", testReleases.value)
        })

        return {
            testReleases,
            packageUrl: config.s3_download_url,
            packageLocalUrl: `smb://${config.build_machine_name}.oa.oclc.org/package`
        }
    }
}
</script>
