<template>
    <router-view/>
</template>

<script>

export default {
  name: "App",
  components: {
  }
}
</script>

<style>
    @import "./style/materialize.min.css";

    html {
        background-color: lightgray;
    }

    #app {
        background-color: lightgray;
    }

    body {
        background-color: #FFF;
        font-family: "Helvetica Neue", Helvetica, sans-serif;
    }

    .center {
        align-content: center;
    }

    .card-edge {
        padding: .5em;
        margin: .75em;
        border-radius: .25em;
    }

    .card-header {
        border-bottom: #ccc;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-bottom-style: solid;
        border-width: 0.075em;
        padding: .25em 0 0.25em 0.6em;
        text-align: left;
        font-weight: 600;
        background-color: lightsteelblue;
        font-size: 19px;
        margin: .5em;
    }

    @font-face {
        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v125/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
    }

    .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;
    }

    .download {
        color: #428bca;
        display: flex;
        margin-left: .65em;
    }

    .download:hover {
        cursor: pointer;
    }

    .space {
        margin-bottom: 1.5em;
    }

    .content {
        text-align: left;
        margin-left: .5em;
    }

    .build-info {
        border-top-style: solid;
        border-top-width: 1px;
        border-color: #ccc;
        padding: 1em 1em 1em 1em;
    }

    .build-header {
        padding: 1em 1.5em 0 1.5em
    }

    .label {
        color: rgb(124, 124, 124);
    }

    .value {
        padding-left: .75em;
    }
</style>
