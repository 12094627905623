<template>
    <app-install-details title="Digby App Install Page" app="digby" filename="Digby" repository="http://git.ent.oclc.org/projects/MOBILE/repos/digby"/>
</template>

<script>
import AppInstallDetails from "@/components/AppInstallDetails.vue"

export default {
    components: {
        AppInstallDetails
    }
}
</script>
