<template>
    <div class="card center card-edge">
        <h5>OCLC Root CA Install Page</h5>
    </div>

    <div class="card center card-edge">
        <div class="content paragraph">
             In order to run in non-prod environments (NAT), you must have the “OCLC Root CA” certificate installed. Otherwise the device won’t be able to use API in that environment and any browser pages open will show a warning about the site not being trusted. This is a one time setup for each device you’ll be using. Make sure you follow these directions on your mobile device.
        </div>

        <div class="space"/>
        <div class="card-header">
            iOS install certificate
        </div>


        <div class="space"/>
        <div class="content paragraph">
            <a class="link" href="https://s3.amazonaws.com/developer.play.mobile.api.oclc.org/static/certificate/oclc.cer">Download the OCLC Root CA certificate</a>
        </div>

        <div class="space"/>
        <div class="content paragraph">
            <a class="link" href="https://s3.amazonaws.com/developer.play.mobile.api.oclc.org/static/certificate/oclc_int_root_ca.cer">Download the OCLC Root CA certificate (INT/NAT)</a>
        </div>

        <div class="space"/>
        <ul class="content list">
            <li>
                Answer “allow”.
            </li>
            <li>
                It should say “Profile Downloaded”.
            </li>
            <li>
                Open settings.  Notice there is a “Profile Downloaded” item at the top.  Select it.
            </li>
            <li>
                Select “Install” on the next screen.
            </li>
            <li>
                Select “Install” again.
            </li>
            <li>
                Go to settings “About”.
            </li>
            <li>
                Scroll to the bottom where it says “Certificate Trust Settings” and select it.
            </li>
            <li>
                Toggle the “OCLC Root CA” to ON and select “Continue”.
            </li>
        </ul>

        <div class="space"/>
        <div class="card-header">
            Android install certificate
        </div>

        <div class="space"/>
        <div class="content paragraph link">
            <a class="link" href="https://s3.amazonaws.com/developer.play.mobile.api.oclc.org/static/certificate/oclc.cer">Download the OCLC Root CA certificate</a>
        </div>

        <div class="space"/>
        <div class="content paragraph link">
            <a class="link" href="https://s3.amazonaws.com/developer.play.mobile.api.oclc.org/static/certificate/oclc_int_root_ca.cer">Download the OCLC Root CA certificate (INT/NAT)</a>
        </div>

        <div class="space"/>
        <ul class="content list">
            <li>
                Notice where you store it.
            </li>
            <li>
                Open settings and search for “certificate” and select “CA certificate”.
            </li>
            <li>
                Select “CA certificate” again.
            </li>
            <li>
                Select “Install anyway”.
            </li>
            <li>
                Find the “oclc.cer” file you just downloaded and select it.
            </li>
            <li>
                You should see “CA certificate installed” at the bottom of the screen.
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .paragraph {
        color: #777;
    }
    .list {
        margin-left: 2em;
    }
    .link {
        color: #428bca;
        margin: 1em;
    }
    ul > li {
        list-style-type: disc;;
    }
</style>
